@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Londrina+Solid&family=Red+Hat+Display&display=swap");

.headroom {
  @apply bg-stone-900 bg-opacity-95;
}

#social-content a {
  @apply text-teal-700 dark:text-teal-500 font-semibold;
}
